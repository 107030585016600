import axios from 'axios'

import { getFBTrackingParams } from '../analytics/useTrackingParams'

import type { StartTrial as FBStartTrial, Subscribe as FBSubscribe } from './fb/types'

import { captureException } from 'shared/sentry'

const IS_DEV = process.env.NODE_ENV !== 'production'

const NEWSLETTER_API_ENDPOINT = process.env.NEXT_PUBLIC_NEWSLETTER_API_ENDPOINT
const NEXT_PUBLIC_ALLOW_LOCAL_NEWSLETTER_API_CALLS =
    process.env.NEXT_PUBLIC_NEWSLETTER_API_ENDPOINT === 'true'

const TEST_CODES = {
    fb_test_code: 'TEST49776', // get this from ad manager to test server events
}

const instance = axios.create({
    baseURL: NEWSLETTER_API_ENDPOINT,
})

/**

 A client to interface with a selection of our lambdas 
 https://github.com/finimize/Nikka-from-the-barrel/blob/master/src/functions/track/index.js

 /track endpoint gives us a place to fire serverside events to be less reliant on 3rd party cookies.

 Certain providers e.g. FB take a test code above in DEV mode, they'll need to be updated each time by going to the relevant provider.
 **/

class FMZTrackingClient {
    _handleEvent = (trackingFn: Function) => {
        try {
            trackingFn()
        } catch (e) {
            captureException(e)
        }
    }

    track = (eventName: string, data?: { [key: string]: any }) => {
        const fbParams = getFBTrackingParams()
        const body = IS_DEV ? { ...data, ...TEST_CODES, ...fbParams } : { ...data, ...fbParams }
        const event = () => instance.post(`/track/${eventName}`, body)
        // It seems like quite a rare use-case we'd want to test this event in dev, and
        // is causes errors otherwise. So we can change a .env variable if we want to actually
        // fire these events to the local test server in dev
        if (!IS_DEV || NEXT_PUBLIC_ALLOW_LOCAL_NEWSLETTER_API_CALLS) {
            this._handleEvent(event)
        }
    }
}

export const client = new FMZTrackingClient()

export type DefaultUserTrackingProperties = {
    email?: string
    userId?: string
}
export type StartTrial = DefaultUserTrackingProperties & FBStartTrial
export type Subscribe = DefaultUserTrackingProperties & FBSubscribe

/*
These events mimic the analytics client events and may need to be de-duplicated e.g. Facebook client event and Facebook server event
*/

export const trackStartTrial = (data?: StartTrial) => client.track('start-trial', data)
export const trackSubscribe = (data?: Subscribe) => client.track('subscribe', data)
export const trackSignup = (data?: DefaultUserTrackingProperties) => client.track('signup', data)
export const trackNewsletterSignup = (data?: DefaultUserTrackingProperties) =>
    client.track('newsletter-signup', data)
