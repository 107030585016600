// Important Finimize URLs

export const termsLink = 'https://www.finimize.com/wp/terms/'
export const privacyLink = 'https://www.finimize.com/wp/privacy-policy/'
export const referralTermsLink = 'https://www.finimize.com/wp/referral-terms/'
export const uberVoucherTermsLink = 'https://www.finimize.com/wp/uber-voucher-terms/'
export const finimizeHomePage = 'https://www.finimize.com'
export const appStoreUrl =
    'https://itunes.apple.com/gb/app/finimize-finance-simplified/id1335577505?mt=8'

export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.finimize.oban&hl=en'
export const appDownloadPage = 'https://www.finimize.com/wp/app/'

// adjust default download url. Redirects user to right app store
// TO DO: change to appsflyer onelink when ready and update variable names
export const appDownloadUrl =
    'https://apps.apple.com/gb/app/finimize-market-investing-news/id1335577505'

// TO DO: change to appsflyer onelink when ready
export const microAdjustedAppDownloadUrl =
    'https://apps.apple.com/gb/app/finimize-market-investing-news/id1335577505'

// Will open up the app or redirect to the relevant app store
export const deepLinkBaseUrl = 'https://app.finimize.com/links/'
export const emailDeepLinkBaseUrl = 'https://link.finimize.com/links/'
// Root url for branch links for attribution
export const branchLinkBaseUrl = 'https://finimize.app.link/'

// emails
export const helpEmail = 'help@finimize.com'

// default discount pages
export const premiumOfferSlug = 'trial24'
export const trialDiscountPageSlug = 'unlock'
export const newsletterDiscountPageSlug = 'unlock-50' // Change below if this changes
export const newsletterDiscountPct = 50 // Likely safe to hardcode this as long as the above remains the same
export const onboardFlowPageSlug = 'onboard'
export const premiumNewsletterDefaultSlug = 'premium-newsletter-trial'

// Exports
export * from './newsletter'
