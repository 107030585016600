import Router from 'next/router'

import { getFBTrackingParams } from './useTrackingParams'

import type { ExperimentsType } from 'components/Experiments'
import { getMessageFromUnknownError } from 'lib/getMessageFromUnknownError'
import { getCookies, setCookie, clearCookie, DAY_IN_SECONDS } from 'lib/useCookies'
import { captureException } from 'shared/sentry'

const LAST_TOUCH_SUFFIX = '[last touch]'
const FIRST_TOUCH_SUFFIX = '[first touch]'

export type QueryAttributionParams = { [key: string]: string }
export type AttributionParams = {
    firstTouch: QueryAttributionParams
    lastTouch: QueryAttributionParams
    base: QueryAttributionParams
}

// Gets us formatted trackable attribution params for mixpanel events
export const getAttributionParams = (): AttributionParams => {
    const utmParams = ['utm_source', 'utm_campaign', 'utm_term', 'utm_content', 'utm_medium']
    const params = Router.query
    const paramsKeys = Object.entries(params)

    const initialParams: AttributionParams = {
        firstTouch: {},
        lastTouch: {},
        base: {},
    }

    if (paramsKeys.length == 0) return initialParams

    const paramsList = paramsKeys.reduce(
        (obj, [key, value]) => {
            const isUtmParam = utmParams.includes(key)
            if (!isUtmParam) return obj

            const param = Array.isArray(value) ? value[0] : value
            if (!param) return obj

            const firstTouchKey = `${key} ${FIRST_TOUCH_SUFFIX}`
            const lastTouchKey = `${key} ${LAST_TOUCH_SUFFIX}`

            obj.firstTouch[firstTouchKey] = param
            obj.lastTouch[lastTouchKey] = param
            obj.base[key] = param

            return obj
        },
        { ...initialParams },
    )

    return paramsList
}

// Set attribution params for 7 days so we can use on other pages
export const ATTRIBUTION_PARAM_PREFIX = '[att]'

export const setAttributionParams = () => {
    const { base } = getAttributionParams()
    const { fbclid } = getFBTrackingParams()
    const newParams = Object.keys(base).length > 0

    const cookieKeyName = ATTRIBUTION_PARAM_PREFIX
    const options = {
        maxAge: DAY_IN_SECONDS * 7, // expire in 7 days
        path: '/',
    }

    if (newParams) {
        setCookie(cookieKeyName, JSON.stringify(base), options)
    }

    if (fbclid) {
        setCookie('_fbc', fbclid, options)
    }
}

export const getLastTouchParamsFromMixpanel = (): QueryAttributionParams => {
    const cookies = getCookies()
    try {
        const lastTouchParams: Record<string, string> = {}

        for (const [cookie, value] of Object.entries(cookies)) {
            if (cookie.startsWith('mixpanel')) {
                const parsedValue = JSON.parse(value) as Record<string, string>

                for (const [param, value] of Object.entries(parsedValue)) {
                    if (param.includes(LAST_TOUCH_SUFFIX)) {
                        lastTouchParams[param] = value
                    }
                }
            }
        }

        return lastTouchParams
    } catch (error) {
        const errorMessage = getMessageFromUnknownError(error)
        captureException(new Error(errorMessage))
        return {}
    }
}

/*

    Checkout cookie logic

    From some pages (e.g. content pages), we want to persist some data through
    to checkout success. This is used for tracking (where they came from, how
    they clicked through to the paywall), but also for redirecting back
    to that content afterwards.


*/

// what we want to check for when we redirect
export const checkoutRedirectKey = 'ref page'

type CheckoutTrackingKeys = 'cta source' | typeof checkoutRedirectKey

const paramToMixpanelKey: { [x: string]: CheckoutTrackingKeys } = {
    'cta-source': 'cta source',
    'ref-page': checkoutRedirectKey, // ref page is also used for post-purchase redirect
}

const CHECKOUT_PARAM_PREFIX = '[Checkout]'

// If these params appear on a page, save them in a cookie,
// then add to the tracking on the next purchase event
export const setCheckoutDataParams = () => {
    const params = Router.query

    Object.entries(params).forEach(([key, val]) => {
        if (key in paramToMixpanelKey && typeof val === 'string') {
            const cookieKeyName = CHECKOUT_PARAM_PREFIX + paramToMixpanelKey[key]
            const options = {
                maxAge: 60 * 30, // expire in 30mins
                path: '/',
            }
            setCookie(cookieKeyName, decodeURIComponent(val), options)
        }
    })
}

export type CheckoutDataParams = {
    [key in CheckoutTrackingKeys]?: string
}

export const getCheckoutDataParams = (): CheckoutDataParams => {
    const cookies = getCookies()

    const checkoutParams = Object.entries(cookies).reduce((list, [cookie, value]) => {
        const isCheckoutCookie = cookie.startsWith(CHECKOUT_PARAM_PREFIX)

        if (!isCheckoutCookie) return list

        const mixpanelPropKey = cookie.replace(CHECKOUT_PARAM_PREFIX, '')

        clearCookie(cookie)

        return {
            [mixpanelPropKey]: value,
            ...list,
        }
    }, {})
    return checkoutParams
}

/* 

    getExperimentProperties

    This is used to convert an array of experiments into a flat object of properties
    where the key is the experiment name and the value is the variant name, so that it can
    be sent to mixpanel as super properties.

*/
export const getExperimentProperties = (
    experiments: ExperimentsType,
): { [key: string]: string } => {
    const accumulator: { [key: string]: string } = {}

    return experiments.reduce((acc, experiment) => {
        const key = 'Experiment ' + experiment.name
        acc[key] = experiment.variant.name
        return acc
    }, accumulator)
}
