import { useState, useEffect } from 'react'

import Router from 'next/router'

import type { QueryAttributionParams} from './helpers';
import { ATTRIBUTION_PARAM_PREFIX, getAttributionParams } from './helpers'

import { getCookies } from 'lib/useCookies'
/****
 *
 *  FB pixel utility helpers
 *  For severside events (marketign api) we want to send tracking params to match users
 *  https://developers.facebook.com/docs/marketing-api/conversions-api/
 */

type FBTrackingCookies = {
    fbclid: string
    fbp: string
}

/**
 * getFBTrackingParams gets the Facebook fbp and fbc values.
 *
 * fbp: when the Meta Pixel is installed on a website, and the Pixel uses first-party cookies,
 * the Pixel automatically saves a unique identifier to an _fbp cookie for the website domain if one does not already exist.
 *
 * The fbp event parameter value must be of the form version.subdomainIndex.creationTime.randomnumber, where:
 * version is always this prefix: fb
 * subdomainIndex is which domain the cookie is defined on ('com' = 0, 'facebook.com' = 1, 'www.facebook.com' = 2). If you’re generating this field on a server, and not saving an _fbp cookie, use the value 1.
 * creationTime is the UNIX time since epoch in milliseconds when the _fbp cookie was saved. If you don't save the _fbp cookie, use the timestamp when you first observed or received this fbp value.
 * Randomnumber is generated by the Meta Pixel SDK to ensure every _fbp cookie is unique.
 *
 * fbc: when a user clicks on an ad on Facebook, the link sometimes includes a fbclid query parameter.
 * The fbc event parameter value must be of the form version.subdomainIndex.creationTime.fbclid, where:
 * version is always this prefix: fb
 * subdomainIndex is which domain the cookie is defined on ('com' = 0, 'facebook.com' = 1, 'www.facebook.com' = 2). If you’re generating this field on a server, and not saving an _fbc cookie, use the value 1.
 * creationTime is the UNIX time since epoch in milliseconds when the _fbc cookie was saved. If you don't save the _fbc cookie, use the timestamp when you first observed or received this fbclid value.
 * fbclid is the value for the fbclid query parameter in the page URL.
 *
 * https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 */
export const getFBTrackingParams = (): FBTrackingCookies => {
    const { _fbp, _fbc } = getCookies()
    const { fbclid: fbclidParam } = Router.query
    const _fbclidParam = Array.isArray(fbclidParam) ? fbclidParam[0] : fbclidParam
    const fbclid = _fbclidParam || _fbc

    return { fbp: _fbp, fbclid }
}
export const useFBTrackingParams = (): FBTrackingCookies => {
    const [fbclid, setFbclid] = useState('')
    const [fbp, setFbp] = useState('')

    useEffect(() => {
        const { fbclid, fbp } = getFBTrackingParams()

        setFbclid(fbclid)
        setFbp(fbp)
    }, [])

    return {
        fbp,
        fbclid,
    }
}

export const useAttributionParams = (): { params: QueryAttributionParams } => {
    const [params, setParams] = useState({})

    useEffect(() => {
        const cookies = getCookies()
        const { base } = getAttributionParams()
        const savedParams = cookies[ATTRIBUTION_PARAM_PREFIX]
            ? JSON.parse(cookies[ATTRIBUTION_PARAM_PREFIX])
            : {}

        const hasUrlParams = Object.values(base).length > 0
        const hasSavedParams = Object.values(savedParams).length > 0

        const mostRecentAttributionParams = hasUrlParams ? base : hasSavedParams ? savedParams : {}
        setParams(mostRecentAttributionParams)
    }, [])

    return { params }
}
