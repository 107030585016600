import { useMemo } from 'react'

import type { GetStaticPropsContext, NextPageContext } from 'next'
import type { GraphQLResponse, Record, Variables } from 'relay-runtime'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import { v4 as uuid } from 'uuid'

import { getAuthHeaderFromCookie, getCsrfToken } from './cookieAuth'

import { EXPERIMENTS_PREFIX } from 'components/Experiments/useExperiments'
import { CYPRESS_ENV_HEADER } from 'consts/testing'
import { cookiesToProxyToBackend } from 'lib/cookiesToProxy'
import {
    maxWafChallengeRetries,
    wafCaptchaErrorMessage,
    wafChallengeStatusCode,
    wafChallengeRetryDelayMs,
    wafCaptchaStatusCode,
} from 'modules/Captcha/consts'
import { isServer } from 'utils/isServer'

// only happens on server. The csrf token is not passed
// all the way to the client by default, so we have
// to explicitly pass it over
function copySetCookie(context: NextPageContext | null) {
    return (response: Response) => {
        if (!process.browser) {
            const cookie = response.headers.get('set-cookie') || ''
            if (context && context.res && context.res.setHeader) {
                context.res.setHeader('Set-Cookie', cookie)
            }
        }

        return response
    }
}

export const getRelayUri = (): string => {
    const proxyHost = process.env.PROXY_HOST

    if (isServer) {
        if (!proxyHost) {
            throw new Error('process.env.PROXY_HOST not set')
        }

        return proxyHost
    }

    return '/api/graphql-web'
}

export function getCookiesToForward(cookieHeader?: string): string {
    if (!cookieHeader) return ''

    const cookies = cookieHeader.split(';').map(cookie => cookie.trim())

    const cookieShouldBeProxied = (cookie: string): boolean => {
        // currently in format cookieName=cookieValue
        const cookieName = cookie.split('=')[0]

        if (cookieName.startsWith(EXPERIMENTS_PREFIX)) return true

        return cookiesToProxyToBackend.includes(cookieName)
    }

    return cookies.filter(cookieShouldBeProxied).join(';')
}

function getAuth(context: NextPageContext | null) {
    const req: any = (context && context.req) || undefined

    return getAuthHeaderFromCookie(req)
}

export function getIpAddress(context: NextPageContext | null) {
    if (context && context.req) {
        const { req } = context
        const { connection, socket } = req

        const xForwardedForHeader =
            (req.headers && (req.headers['x-forwarded-for'] as string)) || ''

        // X-Forwarded-For format: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/X-Forwarded-For
        const formattedIP = xForwardedForHeader
        const remoteAddress =
            (connection && connection.remoteAddress) || (socket && socket.remoteAddress)

        const ip = formattedIP || remoteAddress
        return ip || undefined
    }
}

export const getCypressHeader = (context: NextPageContext | null): string | undefined => {
    // Have to lower-case the header name because that's what NextJS does
    const cypressHeader = context?.req?.headers[CYPRESS_ENV_HEADER.toLowerCase()]

    // Coerce the result into a string...otherwise TS complains that string [] is valid
    return !!cypressHeader && typeof cypressHeader === 'string' ? cypressHeader : undefined
}

const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

const invokeFetch = async (
    context: Context,
    headers: HeadersInit,
    operation: { text: string | null | undefined },
    variables: Variables,
    callCount = 0,
): Promise<GraphQLResponse> => {
    return fetch(getRelayUri(), {
        method: 'POST',
        credentials: 'include',
        headers,
        keepalive: false,
        body: JSON.stringify({
            query: operation.text, // GraphQL text from input
            variables,
        }),
    })
        .then(response => {
            if (isNextPageContext(context)) {
                copySetCookie(context)(response)
            }
            return response
        })
        .then(response => {
            // Handle the WAF captcha status code in a particular way so we can build specific
            // behaviour around it: https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-conditional.html
            if (response.status === wafCaptchaStatusCode) {
                throw new Error(wafCaptchaErrorMessage)
            }
            return response
        })
        .then(async (response: Response) => {
            // Handle AWS WAF challenge responses that may occur before the AWS WAF script
            // has fully loaded. If this happens we retry the request after a delay, until
            // a maximum number of retries.
            if (response.status === wafChallengeStatusCode && callCount < maxWafChallengeRetries) {
                await sleep(wafChallengeRetryDelayMs)
                const newHeaders = getFetchHeaders(context, callCount + 1)
                return invokeFetch(context, newHeaders, operation, variables, callCount + 1)
            }
            return response.json()
        })
}

const getFetchHeaders = (context: Context, retryCount: number): HeadersInit => {
    if (isNextPageContext(context) || (!isServer && context === null)) {
        // This is any request not from getStaticProps
        return getHeaders(context, retryCount)
    } else {
        // We don't have access to cookies etc. so can skip a lot of the header logic
        return [
            ...getSharedHeaders(),
            ['X-Static-Request', 'true'],
            ['X-Retry-Count', String(retryCount)],
        ]
    }
}

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
export const fetchQuery = (context: Context) => {
    return (operation: { text: string | null | undefined }, variables: Variables) => {
        const headers = getFetchHeaders(context, 0)
        return invokeFetch(context, headers, operation, variables)
    }
}

// Stole this from the types source code because it
// wasn't exported. Might change.
export interface RecordMap {
    [dataID: string]: Record | null | undefined
}

type Context = NextPageContext | GetStaticPropsContext | null

type EnvProps = {
    initialRecords?: RecordMap
    context: Context
}

const getSharedHeaders = (): [string, string][] => {
    return [
        ['Accept', 'application/json'],
        ['Content-Type', 'application/json'],
        ['X-Correlation-ID', uuid()],
    ]
}

const getHeaders = (context: NextPageContext | null, retryCount: number) => {
    const cookies = getCookiesToForward(context?.req?.headers.cookie)
    const fullCookieHeader = cookies

    // Important: Used for AWS WAF firewall rules so don't remove this
    const envContextHeader: [string, string | undefined] = isServer
        ? ['X-Server-Request', 'true']
        : ['X-Client-Request', 'true']

    const headerKeys: [string, string | undefined][] = [
        ...getSharedHeaders(),
        ['X-Retry-Count', String(retryCount)],
        ['Cookie', fullCookieHeader],
        ['Authorization', getAuth(context)],
        ['X-Access-Token', getAuth(context)],
        ['X-CSRFToken', getCsrfToken() || undefined],
        ['X-Forwarded-For', getIpAddress(context)],
        ['User-Agent', context?.req?.headers['user-agent'] || undefined], // Means that client user agent can be assessed by WAF/server instead of a NodeJS one
        envContextHeader,
        [CYPRESS_ENV_HEADER, getCypressHeader(context)], // Used to whitelist requests from cypress env in test environments
    ]

    const headers: HeadersInit = {}

    for (const [key, value] of headerKeys) {
        if (value) {
            headers[key] = value
        }
    }

    return headers
}

// Type guard to determine if the context is NextPageContext (i.e. not GetStaticPropsContext)
function isNextPageContext(context: Context): context is NextPageContext {
    return context !== null && 'req' in context && context.req !== undefined
}

function createRelayEnvironment({ initialRecords, context }: EnvProps) {
    const recordSource = new RecordSource(
        //@ts-ignore Seems to be a case of Relay getting its types mixed up. Hopefully resolved on a later release
        initialRecords,
    )
    const store = new Store(recordSource)

    return new Environment({
        network: Network.create(fetchQuery(context)),
        store: store,
    })
}

let clientEnvironment: Environment

export default function initEnvironment({ initialRecords, context }: EnvProps): Environment {
    // Publish the initial records to the store if we are re-using (this makes sure statically rendered pages
    // have the correct data when being navigated to client-side)
    initialRecords &&
        clientEnvironment &&
        clientEnvironment.getStore().publish(
            new RecordSource(
                //@ts-ignore Seems to be a case of Relay getting its types mixed up. Hopefully resolved on a later release
                initialRecords,
            ),
        )

    // Make sure to create a new Relay environment for every server-side request so that data
    // isn't shared between connections (which would be bad)
    if (isServer) {
        return createRelayEnvironment({ initialRecords, context })
    }

    // reuse Relay environment on client-side
    if (!clientEnvironment) {
        clientEnvironment = createRelayEnvironment({ initialRecords, context })
    }

    return clientEnvironment
}

export function useEnvironment({ initialRecords, context }: EnvProps) {
    const store = useMemo(() => initEnvironment({ initialRecords, context }), [initialRecords])
    return store
}
