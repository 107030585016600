import { useCallback, useEffect, useState } from 'react'

import type { CookieSerializeOptions } from 'cookie';
import cookie from 'cookie'

export interface Cookies {
    [key: string]: string
}

export type SetCookie = (name: string, value: string, options?: CookieSerializeOptions) => void

// Can be used to set a cookie that expires in n days
export const DAY_IN_SECONDS = 24 * 60 * 60

/*

    NOTE: If you're using these functions in a component,
    make sure you only trigger them onMount

*/

export const getCookies = (): Cookies => {
    if (typeof document === 'undefined') return {}
    return cookie.parse(document.cookie)
}

export const getCookie = (name: string): string => {
    if (typeof document === 'undefined') return ''
    return cookie.parse(document.cookie)[name]
}

export const setCookie: SetCookie = (name, value, options) => {
    document.cookie = cookie.serialize(name, value, options)
}

export const clearCookie = (name: string) => {
    setCookie(name, '', {
        expires: new Date('Thu, 01 Jan 1970 00:00:01 GMT'),
        path: '/',
    })
}

/*

    This hook can be used to get cookies + other fns in a component context

    (as opposed to adding your own useEffect to use the above fns)

*/

export const useCookies = () => {
    const [cookies, setCookies] = useState<Cookies | null>(null)

    useEffect(() => {
        _updateCookies()
    }, [])

    const _updateCookies = useCallback(() => {
        const cookies = getCookies()
        setCookies(cookies)
    }, [setCookies, getCookies])

    const setCookieAndUpdateState: SetCookie = useCallback(
        (name, value, options) => {
            // set real cookie
            setCookie(name, value, options)

            // update state to keep in sync
            _updateCookies()
        },
        [setCookie, _updateCookies],
    )

    const clearCookieAndUpdateState = useCallback(
        (name: string) => {
            // clear real cookie
            clearCookie(name)

            // update state to keep in sync
            _updateCookies()
        },
        [clearCookie, _updateCookies],
    )

    return {
        cookies,
        setCookie: setCookieAndUpdateState,
        clearCookie: clearCookieAndUpdateState,
    }
}
