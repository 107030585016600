import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type { trackFbConversionsAPIEventMutationResponse } from '__generated__/trackFbConversionsAPIEventMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

type InputObject = {
    eventName: string
    currency?: string
    value?: number
    productId?: string
    searchString?: string
    predictedLtv?: number
}

const trackFbConversionsAPIEvent = (relayEnv: Environment) => (input: InputObject) => {
    return createMutationPromise<trackFbConversionsAPIEventMutationResponse>(relayEnv)({
        // @ts-ignore
        mutation: graphql`
            mutation trackFbConversionsAPIEventMutation($input: TrackFBConversionsAPIEventInput!) {
                trackFbConversionsApiEvent(input: $input) {
                    clientMutationId
                }
            }
        `,
        variables: { input },
    })
}

export default trackFbConversionsAPIEvent
