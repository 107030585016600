import styled, { css } from 'styled-components'

import { type LinkProps } from '../Link'

import type { IconKeys } from 'components/Icon'
import Icon from 'components/Icon'
import { LinkWrapper } from 'components/Link/LinkWrapper'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { ColorName, ThemedComponent } from 'theme'
import { fonts } from 'theme'

type AccessibilityProps = {
    id?: string
    /**
     * The button has an accessible label.
     * By default, the accessible name is computed from any text content inside the button element.
     * However, it can also be provided with aria-labelledby or aria-label.
     */
    ariaLabelledby?: string
    ariaLabel?: string
    /**
     * If a description of the button's function is present, the button element has aria-describedby set to the ID of the element containing the description.
     */
    ariaDescribedby?: string
}

type IconProps = {
    /**
     * Positions the icon, if present. Default - 'left'.
     */
    iconPosition?: 'left' | 'right'
    /**
     * Name of the icon to be used.
     */
    icon?: IconKeys
    /**
     * Color of the icon.
     */
    iconColor?: ColorName
    /**
     * Color of the icon when disabled.
     */
    disabledIconColor?: ColorName
}

type ButtonSize = 'small' | 'medium'

export type ButtonBaseProps = {
    children?: string
    /**
     * Button size. Default - 'medium'.
     */
    size?: ButtonSize
    /**
     * @type {boolean} - Default false. Is the button disabled?
     */
    disabled?: boolean
    /**
     * When passed, will override any onClick and the button will act like
     * a Link component, routing the user to the url. Note: this doesn't change
     * the styling.
     */
    url?: LinkProps['url']
    /**
     * Pass an as component to make the button something different.
     * Note: this may change the styling.
     */
    as?: BoxProps['as']
    /**
     * Function to be triggered on button click.
     */
    onClick?: () => void
    /**
     * @type {'button' | 'submit' | 'reset'} - Default 'button'. The type of the button.
     */
    type?: 'button' | 'submit' | 'reset'
    /**
     * @type {boolean}. Makes the button fill the width of the parent component.
     */
    fullWidth?: boolean
    /**
     * No idea what this is used for so removed them
     * if anything breaks we can add them back.
     */
    // target?: string
    // params?: { [key: string]: any }
} & IconProps &
    AccessibilityProps

type StyledButtonBaseProps = Omit<ButtonBaseProps, 'size'> & { $size: ButtonSize } & {
    isIconOnly: boolean
}

// Experimental way to get typing for theme
type StyledProps = ThemedComponent<StyledButtonBaseProps>

export const ButtonBase = ({
    children,
    as = 'button',
    size = 'medium',
    url,
    disabled = false,
    icon,
    iconPosition = 'left',
    iconColor,
    disabledIconColor,
    fullWidth,
    onClick,
    ariaDescribedby,
    ariaLabel,
    ariaLabelledby,
    ...props
}: ButtonBaseProps) => {
    const isIconOnly = !children && !!icon

    return (
        <LinkWrapper url={url}>
            <StyledButtonBase
                $size={size}
                disabled={disabled}
                aria-disabled={disabled}
                as={as}
                iconPosition={iconPosition}
                fullWidth={fullWidth}
                onClick={onClick}
                isIconOnly={isIconOnly}
                aria-labelledby={ariaLabelledby}
                aria-label={ariaLabel}
                aria-describedby={ariaDescribedby}
                {...props}
            >
                {icon && (
                    <Icon
                        icon={icon}
                        color={!disabled ? iconColor : disabledIconColor}
                        marginRight={!isIconOnly && iconPosition === 'left' ? '0.5em' : undefined}
                        marginLeft={!isIconOnly && iconPosition === 'right' ? '0.5em' : undefined}
                        size={size}
                    ></Icon>
                )}
                {children}
            </StyledButtonBase>
        </LinkWrapper>
    )
}

export default ButtonBase

const StyledButtonBase = styled(Box)<StyledProps>`
    display: flex;
    align-items: center;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.radii.rounded};
    flex-direction: ${({ iconPosition }) => (iconPosition === 'right' ? 'row-reverse' : 'row')};
    border: 1px solid ${({ theme }) => theme.palette.neutral[999]};

    box-shadow: 3px 3px 0 0 ${({ theme }) => theme.palette.neutral[999]};
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease;
    transition: transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;

    line-height: 1.2;
    font-weight: ${fonts.weight.bold};
    white-space: nowrap;

    text-decoration: none;

    &:hover:not([disabled]),
    &:focus:not([disabled]) {
        box-shadow: 0 0 0 0 ${({ theme }) => theme.palette.neutral[999]};
        -webkit-transform: translate(4px, 4px);
        -ms-transform: translate(4px, 4px);
        transform: translate(4px, 4px);
        text-decoration: none;
    }

    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
                  justify-content: center;
              `
            : css`
                  width: fit-content;
              `}
    ${({ $size, isIconOnly }) => {
        switch ($size) {
            case 'small':
                if (isIconOnly) {
                    return css`
                        padding: 0.35em;
                    `
                }
                return css`
                    font-size: ${fonts.size.XS};
                    padding: 0.35em 0.8em;
                `
            case 'medium':
            default:
                if (isIconOnly) {
                    return css`
                        padding: 0.7em;
                    `
                }
                return css`
                    font-size: ${fonts.size.M};
                    padding: 0.7em 1.5em;
                `
        }
    }}
`
