import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

import { type LinkProps } from 'components/Link'
import { LinkWrapper } from 'components/Link/LinkWrapper'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { RadiiProps } from 'components/types/props'
import type { ThemedComponent, ColorName } from 'theme'
import { getColor } from 'theme'
import type { Omit } from 'types'

type LocalCardProps = {
    children?: React.ReactNode
    /**
     * @deprecated use fullScreen instead.
     */
    isBlock?: boolean
    fullScreen?: boolean
    elevation?: 0 | 1 | 2 | 3 | 4
    border?: ColorName
    radius?: RadiiProps
    /**
     * Determines whether the Card component will link to another page.
     * This will also add extra on hover styling
     */
    url?: LinkProps['url']
}

export type CardProps = Omit<BoxProps, 'border'> & LocalCardProps

const Card = ({
    className,
    border,
    fullScreen,
    elevation = 1,
    radius = 'large',
    children,
    url,
    ...props
}: CardProps) => {
    return (
        <LinkWrapper url={url}>
            <StyledCard
                className={className}
                border={border}
                fullScreen={fullScreen}
                radius={radius}
                elevation={elevation}
                {...props}
            >
                {children}
            </StyledCard>
        </LinkWrapper>
    )
}

export default Card

type CardStyledProps = ThemedComponent<CardProps>

const StyledCard = styled(Box)<CardStyledProps>`
    display: inline-block;
    box-sizing: border-box;
    vertical-align: top;

    background-color: ${props => (props.bg ? props.bg : props.theme.palette.neutral[0])};
    ${props =>
        props.radius &&
        css`
            border-radius: ${props.theme.radii[props.radius]};
        `}
    ${props =>
        !!props.elevation &&
        css`
            box-shadow: ${props.theme.elevation[props.elevation]};
        `}
        ${props =>
        (props.isBlock || props.fullScreen) &&
        css`
            width: 100%;
        `}
        ${props =>
        props.border &&
        css`
            border: 2px solid ${transparentize(0.56, getColor(props.border) || '')};
        `}
        ${props =>
        props.url &&
        css`
            &:hover {
                background-color: ${({ theme }) => props.url && theme.palette.info[50]};
                box-shadow: ${({ theme }) => props.url && theme.elevation[0]};
            }
        `};
`
