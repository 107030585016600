import { useState, useEffect, useCallback, useMemo } from 'react'

import constate from 'constate'
import add from 'date-fns/add'

import _CookieClient from 'lib/freeArticleAllowance/articleAllowanceCookieClient'
import {
    ARTICLE_WINDOW_MONTHS,
    FREE_ARTICLES_ALLOWED_EACH_WINDOW,
} from 'lib/freeArticleAllowance/consts'
import { useCookies } from 'lib/useCookies'

/*

    Provides all the helper functions to set/handle the cookies
    that are used for the logic to offer x free articles / number of months (configured with ARTICLE_WINDOW_MONTHS)
    to browsing users

    Logic is: You have to be first opted in to receiving free content. This could be from
    perfoming an action, or just acccessing the home page.
    If you are opted in, then from the moment you first read an article, you get a number of months
    to read x articles. You then get another x in the next window

    To handle this, we set 4 types of cookies:
     1. A long-lasting cookie that means you're opted in. This can be set wherever we want. [used server-side]
     2. An ID array for the articles completed that window, with an expiry of a number of months.
     3. A date for when your window started, with an expiry of a number of months.
     4. A cookie that is set once your allowance is up, with an expiry of the end of the window. This
        tells the server to show you content. This is easy to hack, but is a simple way to let us 
        experiment with something that works [used server-side] [Unfortunately we can't set a cookie when the allowance starts,
        because you can't set a cookie to "start" at the next window]

    From these we know:
        - You have content access if cookie 1 is set and 4 is not set
        - A count of how many articles you have left this window - using the length of ID array if 4 not set
    

    ...Also check below the hook for a fun hack we've had to do for one use-case
 */

interface ArticleCountState {
    // helper variables to display UI
    // values are set to null while the cookies init
    remainingFreeArticles: number | null
    totalArticleCountThisAllowanceWindow: number | null
    freeArticlesPerAllowanceWindow: number
}

type ReturnValue = {
    cookiesInitialised: boolean
    decrementRemainingFreeArticles: (globalContentPieceId: string, isFreeContent: boolean) => void
} & ArticleCountState

const useFreeArticleCookies = (): ReturnValue => {
    const { setCookie, cookies } = useCookies()
    const [cookiesInitialised, setCookiesInitialised] = useState<boolean>(false)
    const [articleCountState, setArticleCountState] = useState<ArticleCountState>({
        remainingFreeArticles: null,
        totalArticleCountThisAllowanceWindow: null,
        freeArticlesPerAllowanceWindow: FREE_ARTICLES_ALLOWED_EACH_WINDOW,
    })

    useEffect(() => {
        cookies && setCookiesInitialised(true)
        cookies && _updateArticleCountState()
    }, [cookies])

    const CookieClient = useMemo(
        () => (cookies ? new _CookieClient(cookies, setCookie) : null),
        [cookies],
    )

    const _updateArticleCountState = useCallback(() => {
        // Take the most recent cookie update and set the article
        // counts
        if (!CookieClient) return

        const idsCompleted = CookieClient.getIdsCompleted()

        const totalArticleCountThisAllowanceWindow = idsCompleted?.length || 0
        const remainingFreeArticles = Math.max(
            FREE_ARTICLES_ALLOWED_EACH_WINDOW - totalArticleCountThisAllowanceWindow,
            0,
        )

        const updatedArticleState = {
            remainingFreeArticles,
            totalArticleCountThisAllowanceWindow,
            freeArticlesPerAllowanceWindow: FREE_ARTICLES_ALLOWED_EACH_WINDOW,
        }

        setArticleCountState(updatedArticleState)
    }, [CookieClient, setArticleCountState])

    const decrementRemainingFreeArticles = useCallback(
        (globalContentPieceId: string, isFreeContent: boolean) => {
            // NOTE: This needs a globalContentPieceId (i.e. an ID that doesn't change
            // depending on whether the user is on ContentPiece/PublicContentPiece). There
            // is a field we can use for this
            // To be used after completing a content piece
            // Needs cookies to be initialised to work
            if (!CookieClient) return

            // Some content is free (not paywalled) so this should not
            // count towards our allowance
            if (isFreeContent) return

            /*
            Outline structure is:
                First make sure we're eligible
                If the user hasn't got a date cookie set, then initialise (set date and ID cookies)
                If the user has completed their article allowance, set the allowance finished cookie
                If the user has articles left in their allowance, and the ID to the ID array cookie
        */

            const hasFinishedAllowance = CookieClient.getAllowanceFinishedCookie()
            const idsCompleted = CookieClient.getIdsCompleted() || []
            const dateWindowStarted = CookieClient.getDateWindowStartedCookie()

            // most likely the user has premium or has used their articles
            if (hasFinishedAllowance) return

            // We've already started the allowance if a date has been set
            if (!dateWindowStarted) {
                // Nothing has been initialised yet
                // We'll set the ID cookie as part of the logic below
                CookieClient.setDateWindowStartedCookie()
            }

            // Now we need to add the ID to the array...

            const idAlreadyCompleted = idsCompleted.includes(globalContentPieceId)
            if (idAlreadyCompleted) {
                // in this case, this could have been a duplicate event. Don't decrement
                return
            }

            const updatedArticlesArray = [...idsCompleted, globalContentPieceId]
            CookieClient.setIdsCompleted(updatedArticlesArray)

            // Now check if we've hit our article limit
            const numberOfArticlesCompleted = updatedArticlesArray.length
            if (numberOfArticlesCompleted >= FREE_ARTICLES_ALLOWED_EACH_WINDOW) {
                const expiry = dateWindowStarted || new Date()
                // in this case, we've hit the limit and need to set the cookie to turn off access
                const endOfWindow = add(expiry, { months: ARTICLE_WINDOW_MONTHS })

                CookieClient.setAllowanceFinishedCookie(endOfWindow)
            }
        },
        [CookieClient],
    )

    return {
        cookiesInitialised,
        decrementRemainingFreeArticles,
        ...articleCountState,
    }
}

export const [FreeArticleCookiesProvider, useFreeArticleCookiesContext] =
    constate(useFreeArticleCookies)
