import type { ReactElement } from 'react'

import type { LinkProps } from 'next/link'
import Link from 'next/link'
import styled from 'styled-components'

import { cardHeadingHoverTrigger } from 'components/ContentCards/DetailedPreviewCard/CardHeading'

type LinkWrapperProps = {
    url?: LinkProps['href']
    onClick?: LinkProps['onClick']
    dataCy?: string
    children: ReactElement
}
/*
 * This is a wrapper component used when we want another component to
 * behave as a Link if there is a url present.
 * It parses the url to be passed to the next/link component and
 * adds logic for external links.
 */
export const LinkWrapper = ({ url, onClick, dataCy, children }: LinkWrapperProps) => {
    if (!url) return children

    let pathname: string | undefined

    if (typeof url !== 'string') {
        if (url?.pathname) {
            pathname = url.pathname
        }
    } else {
        pathname = url
    }

    if (!pathname) {
        console.warn('<Link/> component requires a valid "url={}" prop')
    }

    const isExternalLink = pathname ? /^(http|mailto:)/.test(pathname) : false

    return (
        <Wrapper
            href={url}
            target={isExternalLink ? '_blank' : undefined}
            rel={isExternalLink ? 'noopener noreferrer' : undefined}
            data-cy={dataCy}
            onClick={onClick}
        >
            {children}
        </Wrapper>
    )
}

const Wrapper = styled(Link)`
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    height: 100%;

    &:hover,
    :active,
    :visited {
        text-decoration: none;
    }

    &:hover {
        ${cardHeadingHoverTrigger}
    }
`
